import * as React from 'react'
import ERoute from '../../ERoute'
import { Link } from 'react-router-dom'
import Icon, { IconTypes } from '../Icons/Icon'

export interface IBreadcrumb {
	content: string
	url: string | ERoute
	icon?: IconTypes | 'none'
	hasSeperator?: boolean
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}
			
type IProps = IBreadcrumb

export default class Breadcrumb extends React.PureComponent<IProps> {
	static defaultProps = {
		icon: 'back-chevron',
		hasSeperator: false,
		onClick: () => {},
	}

	render () {
		const { content, url, icon, hasSeperator, onClick } = this.props

		return (
			<>
				{hasSeperator && <div className='breadcrumb-seperator'>/</div>}
				<Link key={content} to={url} className='breadcrumb' onClick={onClick}>
					{!hasSeperator && icon && icon !== 'none' && <span className='breadcrumb-icon'>
						<Icon icon={icon} />
					</span>}

					<span className='breadcrumb-content'>
						{content}
					</span>
				</Link>
			</>
		)
	}
}