import * as React from 'react'
import Breadcrumb, { IBreadcrumb } from './Breadcrumb'

interface IProps {
	breadcrumbs: IBreadcrumb[]
}

export default class Breadcrumbs extends React.PureComponent<IProps> {
	render () {
		const { breadcrumbs } = this.props


		return (
			<div className='breadcrumbs'>
				{breadcrumbs.map((breadcrumb, index) => {
					return (
						<Breadcrumb
							key={breadcrumb.content}
							content={breadcrumb.content}
							url={breadcrumb.url}
							hasSeperator={index !== 0}
							onClick={breadcrumb.onClick}
						/>
					)
				})}
			</div>
		)
	}
}