import * as React from 'react'
import Icon from '../Icons/Icon'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import Breadcrumbs from '../Breadcrumb/Breadcrumbs'
import { IBreadcrumb } from '../Breadcrumb/Breadcrumb'
import Popover from '../Popover/Popover'

interface IState {
  actionMenuActive: boolean
}

interface IProps {
  breadcrumbs?: IBreadcrumb[]
  title?: string | JSX.Element
  titleColor?: string
  seperator?: boolean
  mainActions?: IActionListItem[]
  navigation?: JSX.Element
  onMainActionClick?: (key: string) => void
}

export default class PageHeader extends React.Component<IProps, IState> {
  static defaultProps = {
    mainActions: [],
    breadcrumbs: [],
    seperator: false
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      actionMenuActive: false
    }

    this.onToggleActionMenuClick = this.onToggleActionMenuClick.bind(this)
    this.onClosePopover = this.onClosePopover.bind(this)
    this.onMainActionClick = this.onMainActionClick.bind(this)
  }

  onToggleActionMenuClick() {
    const { actionMenuActive } = this.state

    this.setState({
      actionMenuActive: !actionMenuActive
    })
  }

  onMainActionClick(key) {
    const { onMainActionClick } = this.props

    this.setState({ actionMenuActive: false })

    switch (key) {
      case 'edit':
      case 'delete':
    }

    if (onMainActionClick) onMainActionClick(key)
  }

  onClosePopover() {
    this.setState({ actionMenuActive: false })
  }

  render() {
    const {
      breadcrumbs,
      title,
      titleColor,
      seperator,
      mainActions,
      navigation
    } = this.props

    const { actionMenuActive } = this.state

    return (
      <div className={`page-header ${seperator ? 'with-seperator' : ''}`}>
        <div className='page-header-navigation'>
          {breadcrumbs.length > 0 && <div className='page-header-breadcrumbs'>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>}

          {mainActions.length > 0 && <div className='page-header-action-menu'>
            <div className='page-header-action-menu-wrapper'>
              <Popover
                activator={
                  <a href='javascript://' className='page-action-menu-button' onClick={this.onToggleActionMenuClick}>
                    <Icon icon='solaris-dots' />
                  </a>
                }
                active={actionMenuActive}
                placement='bottom-end'
                onClose={this.onClosePopover}
              >
                <div style={{ marginTop: 8 }}>
                  <ActionList
                    actions={mainActions}
                    onClick={(key) => this.onMainActionClick(key)}
                    hasIndicator
                  />
                </div>
              </Popover>
            </div>
          </div>}
        </div>

        {
          title && <div className='page-header-content'>
            {titleColor && <div className='page-header-title-color' style={{ backgroundColor: titleColor }} />}
            <h1>{title}</h1>
          </div>
        }

        <div className='page-header-navigation'></div>

        {navigation && navigation}
      </div >
    )
  }
}