import * as React from 'react'
import * as Sentry from '@sentry/browser'
import App from '../containers/Accountant/App'
import { Provider } from 'react-redux'
import store from '../store'
import { toast } from 'react-toastify'
import { SENTRY_DSN } from '../Constants'
import { AccountantCurrentUser } from '../types'

Sentry.init({ dsn: SENTRY_DSN })

interface IProps {
    currentUser: AccountantCurrentUser
}

export default class Application extends React.Component<IProps> {
    componentWillMount() {
        toast.configure({
            position: 'bottom-center',
            closeButton: false,
        })
    }

    render() {
        return (
            <Provider store={store}>
                <App />
            </Provider>
        )
    }
}