import * as React from 'react'
import ModalHeader from '../Parts/ModalHeader'
import ModalWindow from '../Parts/ModalWindow'
import ModalMiddle from '../Parts/ModalMiddle'
import PowerSelect from '../../Form/PowerSelect'
import TooltipError from '../../Tooltips/ErrorTooltip'
import QuarterPicker from '../../Form/QuarterPicker'
import Utils from '../../../utilities/Utils'
import TimeHelper from '../../../helpers/TimeHelper'
import { InvoicesController } from '../../../controllers/accountant'
import Notification from '../../../utilities/Notification'
import Button from '../../Button/Button'
import ModalLoader from '../Parts/ModalLoader'
import ModalContent from '../Parts/ModalContent'
import ReactSelectTheme from '../../Form/ReactSelectTheme'

interface IProps {
  close: () => void
}

interface IState {
  didInitialLoad: boolean
  typeOptions: { label: string, value: 'pdf' | 'ubl' }[]
  selectedType: 'pdf' | 'ubl'
  yearOptions: number[]
  selectedYear: number
  selectedQuarter: number
  selectedBookingType: 'all' | 'booked' | 'unbooked',
  errors: any
  isSubmitting: boolean
}

class ExportInvoicesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      typeOptions: [{ label: 'PDF', value: 'pdf' }, { label: 'UBL', value: 'ubl' }],
      selectedType: 'pdf',
      yearOptions: TimeHelper.getYearRange((new Date()).getFullYear() - 2),
      selectedYear: new Date().getFullYear(),
      selectedQuarter: Utils.getCurrentQuarter(),
      selectedBookingType: 'all',
      errors: {},
      isSubmitting: false,
    }

    this.onTypeSelect = this.onTypeSelect.bind(this)
    this.onYearSelect = this.onYearSelect.bind(this)
    this.onBookingType = this.onBookingType.bind(this)
    this.onQuarterPicked = this.onQuarterPicked.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInvoiceExportModalClose = this.onInvoiceExportModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    requestAnimationFrame(() => { this.setState({ didInitialLoad: true }) })
  }

  onTypeSelect(option) {
    this.setState({
      selectedType: option.value
    })
  }

  onYearSelect(option) {
    this.setState({
      selectedYear: option.value,
    });
  }

  onQuarterPicked(selectedQuarter: number) {
    this.setState({
      selectedQuarter: selectedQuarter,
    })
  }

  onBookingType(option) {
    this.setState({
      selectedBookingType: option.value,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { selectedType, selectedYear, selectedQuarter, selectedBookingType } = this.state
    const { close } = this.props

    this.setState({ isSubmitting: true })

    InvoicesController
      .requestExport(selectedType, selectedYear, selectedQuarter, selectedBookingType)
      .then(response => {
        if (response.success) {
          // Show notification
          Notification.notifySuccess('U ontvangt het archief met facturen voor de geselecteerde periode via email.')
          close()
        }
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  onInvoiceExportModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const {
      didInitialLoad,
      typeOptions,
      selectedType,
      yearOptions,
      selectedYear,
      selectedQuarter,
      selectedBookingType,
      errors,
      isSubmitting,
    } = this.state

    const selectedTypeOption = typeOptions.find(option => option.value === selectedType)
    const yearPowerOptions = yearOptions.map(year => ({ label: `${year}`, value: `${year}` }))
    const selectedYearOption = yearPowerOptions.find(option => Number(option.value) === selectedYear)
    const bookingOptions = [
      { label: 'Alle', value: 'all' },
      { label: 'Niet ingeboekt', value: 'unbooked' },
      { label: 'Ingeboekt', value: 'booked' },
    ]
    const selectedBookingOption = bookingOptions.find(option => option.value === selectedBookingType)

    return (
      <ModalWindow>
        <ModalHeader
          title='Exporteer facturen'
          onCloseClick={this.onInvoiceExportModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit} style={{ width: '100%' }}>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>
                      {'Type'}
                    </label>

                    <PowerSelect
                      options={typeOptions}
                      value={selectedTypeOption}
                      onChange={this.onTypeSelect}
                      noOptionsMessage={() => 'Geen optie gevonden'}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>
                      {'Jaar'}
                    </label>

                    <PowerSelect
                      options={yearPowerOptions}
                      value={selectedYearOption}
                      onChange={this.onYearSelect}
                      noOptionsMessage={() => 'Geen optie gevonden'}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>
                      {'Boekhouding status'}
                    </label>

                    <PowerSelect
                      options={bookingOptions}
                      value={selectedBookingOption}
                      onChange={this.onBookingType}
                      noOptionsMessage={() => 'Geen optie gevonden'}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>Kwartaal</label>
                    <QuarterPicker
                      value={selectedQuarter}
                      onSelect={this.onQuarterPicked}
                    />
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text='Exporteer'
                  onClick={this.onFormSubmit}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </div>

        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

export default ExportInvoicesModal