import * as React from 'react'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import CardEmptyInfo from '../../components/Card/CardEmptyInfo'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import { ExpensesController } from '../../controllers/accountant'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import PageLoader from '../../components/Page/PageLoader'
import { Helmet } from 'react-helmet'
import DateInput from '../../components/Form/DateInput'
import Modal from '../../components/Modals/Modal'
import ExportExpensesModal from '../../components/Modals/Accountant/ExportExpensesModal'
import ResourceTable from '../../components/Resource/ResourceTable'
import ButtonPanel from '../../components/Button/ButtonPanel'
import { IActionListItem } from '../../components/ActionList/ActionList'
import ResourceTableRow from '../../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../../components/Resource/ResourceTableRowData'
import ResourceTableRowActions from '../../components/Resource/ResourceTableRowActions'
import Badge from '../../components/Badge/Badge'
import { AccountantCurrentUser, Expense } from '../../types'

interface IStateToProps {
  currentAccountant: AccountantCurrentUser
}

interface IDispatchToProps { }


type IProps = IStateToProps & IDispatchToProps

interface IState {
  expenses: Expense[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  showExportExpensesModal: boolean
}

class Expenses extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      expenses: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      showExportExpensesModal: false,
    }

    this.onExportExpensesClick = this.onExportExpensesClick.bind(this)
    this.onExportExpensesModalClose = this.onExportExpensesModalClose.bind(this)
    this.onBookedOnChange = this.onBookedOnChange.bind(this)
    this.onActionClick = this.onActionClick.bind(this)
    this.onTableLoadMoreClick = this.onTableLoadMoreClick.bind(this)
  }
  componentWillMount() {
    this.fetchExpenses()
  }

  fetchExpenses(page = 1) {
    ExpensesController
      .getExpenses({ page: page })
      .then(response => {
        const { expenses, current_page, total_pages } = response

        this.setState({
          expenses: [...expenses],
          didInitialLoad: true,
          currentPage: current_page,
          totalPages: total_pages,
        });
      })
      .catch(console.error)
  }

  onExportExpensesClick() {
    this.setState({
      showExportExpensesModal: true
    })
  }

  onExportExpensesModalClose() {
    this.setState({
      showExportExpensesModal: false
    })
  }

  onBookedOnChange(index: number, bookedOn) {
    const { expenses } = this.state

    if (moment.isMoment(bookedOn)) {
      expenses[index].booked_on = bookedOn.format('YYYY-MM-DD')

      ExpensesController
        .update(expenses[index])
        .then(ledgerItem => {
          this.setState({
            expenses: [...expenses]
          })
        }).catch(console.error)
    }
  }

  onActionClick(key: string, expense: Expense) {
    switch (key) {
      case 'download':
        window.location.assign(`/accountants/expenses/${expense.id}/download`)
        break
    }
  }

  onTableLoadMoreClick(e) {
    e.preventDefault();

    const { currentPage } = this.state;

    this.fetchExpenses(currentPage + 1);
  }

  render(): JSX.Element {
    const { workspace: { setting } } = this.props.currentAccountant
    const { expenses, didInitialLoad, showExportExpensesModal, currentPage, totalPages } = this.state;

    return (
      <>
        <Helmet>
          <title>Bizzey | Uitgaven</title>
        </Helmet>
        <ScrollToTopOnMount />
        {!didInitialLoad && <PageLoader />}


        {didInitialLoad && <ResourceTable
          data={expenses}
          actionsRight={[
            <ButtonPanel
              icon='download'
              text={'Export expenses'}
              onClick={this.onExportExpensesClick}
            />
          ]}
          headers={[
            { title: 'Supplier' },
            { title: 'Category' },
            { title: 'Invoice date' },
            { title: 'Booked on' },
            { title: 'Amount', align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(expense: Expense, index) => {
            let actions: IActionListItem[] = []

            if (expense.attachment_url) {
              actions.push({ key: 'download', content: 'Download' })
            }

            return (
              <ResourceTableRow key={expense.id}>
                <ResourceTableRowData verticalAlign='top'>
                  <div>
                    <b>
                      {expense.supplier ? expense.supplier.name : '-'}
                    </b>
                  </div>

                  {expense.supplier && expense.supplier.vat_number && <div style={{ fontSize: 12 }}>
                    {expense.supplier.vat_number}
                  </div>}
                </ResourceTableRowData>
                <ResourceTableRowData verticalAlign='top'>
                  {expense.category ? <Badge type='grey' text={expense.category.name} /> : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData verticalAlign='top'>
                  {expense.invoiced_on ? moment(expense.invoiced_on).format('ll') : '-'}
                </ResourceTableRowData>
                <ResourceTableRowData style={{ maxWidth: 100 }}>
                  <DateInput
                    name='booked_on'
                    dateFormat={setting.date_format}
                    timeFormat={false}
                    initialValue={moment(expense.booked_on)}
                    inputProps={{ placeholder: setting.date_format }}
                    onChange={(bookedOn) => this.onBookedOnChange(index, bookedOn)}
                    closeOnSelect
                  />
                </ResourceTableRowData>
                <ResourceTableRowData textAlign='right'>
                  <div>
                    <b>
                      {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, expense.net_total)}
                    </b>
                  </div>
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onActionClick(key, expense)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchExpenses(page)}
          renderEmpty={<CardEmptyInfo
            icon='invoice'
            description={'No expenses found'}
          />}
          stickyHeader={true}
          maxHeight='65vh'
        />}

        <Modal show={showExportExpensesModal} onClose={this.onExportExpensesModalClose} >
          <ExportExpensesModal close={this.onExportExpensesModalClose} />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentAccountant,
    }
  } = state

  return {
    currentAccountant: currentAccountant,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses)