import * as React from 'react'
import { PureComponent } from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import AccountantLayout from '../../layouts/AccountantLayout'
import ERouteAccountant from '../../accountant/ERouteAccountant'

interface IStateToProps { }

type IProps = IStateToProps

class App extends PureComponent<IProps> {
  render(): JSX.Element {
    return (
      <>
        <Router>
          <Switch>
            <Route path={ERouteAccountant.PATH_APP_LAYOUT} component={AccountantLayout} />
            <Redirect to={ERouteAccountant.PATH_APP_LAYOUT} />
          </Switch>
        </Router>
      </>
    )
  }
}


export default App