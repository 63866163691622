import * as React from 'react'

interface IProps {
	value: number
	onSelect: (quarter: number) => void
}

export default class QuarterPicker extends React.PureComponent<IProps> {
	render () {
		const { value, onSelect } = this.props
		const quarters = [1, 2, 3, 4]

		return (
			<div className='quarter-picker'>
				{quarters.map(quarter => {
					const isActive = quarter === value
					return (
						<div key={quarter} className={`quarter-picker-item ${isActive ? 'is-active' : ''}`} onClick={() => onSelect(quarter)}>
							{`Q${quarter}`}
						</div>
					)
				})}
			</div>
		)
	}
}