import * as React from 'react'
import { Switch, Route } from 'react-router'
import NoMatch from '../containers/NoMatch'
import ERouteAccountant from '../accountant/ERouteAccountant'
import Dashboard from '../containers/Accountant/Dashboard'

export default class AccountantLayout extends React.PureComponent {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Switch>
				<Route path={ERouteAccountant.PATH_DASHBOARD} component={Dashboard} />
				<Route component={NoMatch} />
			</Switch>
		)
	}
}