import { Expense } from "../../types";
import Utils from "../../utilities/Utils";

export default class ExpensesController {
  static getExpenses(params: object) {
    return fetch(`/accountants/expenses?${Utils.encodeQueryData(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(res => res.json())
  }

  static requestExport(year: number, quarter: number, bookingStatus: 'all' | 'booked' | 'unbooked') {
    return fetch(`/accountants/expenses/export?${Utils.encodeQueryData({ year: year, quarter: quarter, booking_status: bookingStatus })}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then(res => res.json())
  }

  static update(expense: Expense) {
    return fetch(`/accountants/expenses/${expense.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({ expense: expense })
    })
      .then(res => res.json())
  }
}
