import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import CardEmptyInfo from '../../components/Card/CardEmptyInfo'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import { IActionListItem } from '../../components/ActionList/ActionList'
import moment from '../../utilities/Moment'
import { InvoicesController } from '../../controllers/accountant'
import NumberFormatter from '../../utilities/NumberFormatter'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppState } from '../../store'
import PageLoader from '../../components/Page/PageLoader'
import DateInput from '../../components/Form/DateInput'
import { Helmet } from 'react-helmet'
import ResourceTable from '../../components/Resource/ResourceTable'
import ResourceTableRow from '../../components/Resource/ResourceTableRow'
import ResourceTableRowData from '../../components/Resource/ResourceTableRowData'
import ResourceTableRowActions from '../../components/Resource/ResourceTableRowActions'
import ButtonPanel from '../../components/Button/ButtonPanel'
import Modal from '../../components/Modals/Modal'
import ExportInvoicesModal from '../../components/Modals/Accountant/ExportInvoicesModal'
import { AccountantCurrentUser, LedgerItem } from '../../types'


interface IStateToProps {
  currentAccountant: AccountantCurrentUser
}

interface IDispatchToProps { }


type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IState {
  ledgerItems: LedgerItem[]
  currentPage: number
  totalPages: number
  didInitialLoad: boolean
  showExportInvoicesModal: boolean
}

class Invoices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ledgerItems: [],
      currentPage: 1,
      totalPages: 1,
      didInitialLoad: false,
      showExportInvoicesModal: false,
    }

    this.onExportInvoicesClick = this.onExportInvoicesClick.bind(this)
    this.onExportInvoicesModalClose = this.onExportInvoicesModalClose.bind(this)
    this.onBookedOnChange = this.onBookedOnChange.bind(this)
    this.onActionClick = this.onActionClick.bind(this)
  }

  onExportInvoicesClick() {
    requestAnimationFrame(() => {
      this.setState({ showExportInvoicesModal: true })
    })
  }

  onExportInvoicesModalClose() {
    this.setState({
      showExportInvoicesModal: false
    })
  }

  onBookedOnChange(index: number, bookedOn) {
    const { ledgerItems } = this.state

    if (moment.isMoment(bookedOn)) {
      ledgerItems[index].booked_on = bookedOn.format('YYYY-MM-DD')

      InvoicesController
        .update(ledgerItems[index])
        .then(ledgerItem => {
          this.setState({
            ledgerItems: [...ledgerItems]
          })
        }).catch(console.error)
    }
  }

  onActionClick(key: string, ledgerItem: LedgerItem) {
    switch (key) {
      case 'download_pdf':
        LedgerItemHelper.download(ledgerItem, 'pdf')
        break
      case 'download_ubl':
        LedgerItemHelper.download(ledgerItem, 'ubl')
        break
    }
  }

  fetchLedgerItems(page = 1) {
    InvoicesController
      .getInvoices(page)
      .then(response => {
        const { current_page, total_pages, invoices: newLedgerItems } = response

        this.setState({
          ledgerItems: newLedgerItems,
          currentPage: current_page,
          totalPages: total_pages,
          didInitialLoad: true,
        });
      })
      .catch(console.error)
  }

  componentDidMount() {
    this.fetchLedgerItems(1)
  }

  render() {
    const { didInitialLoad, ledgerItems, currentPage, totalPages, showExportInvoicesModal } = this.state;
    const { workspace: { setting } } = this.props.currentAccountant

    return (
      <>
        <Helmet>
          <title>Bizzey | Facturen & Creditnota's</title>
        </Helmet>
        <ScrollToTopOnMount />
        {!didInitialLoad && <PageLoader />}

        {didInitialLoad && <ResourceTable
          data={ledgerItems}
          actionsRight={[
            <ButtonPanel
              icon='download'
              text={'Export invoices'}
              onClick={this.onExportInvoicesClick}
            />
          ]}
          headers={[
            { title: 'Number' },
            { title: 'Contact' },
            { title: 'Booked on' },
            { title: 'Amount', align: 'right' },
            { title: '', stickyRight: '0px' },
          ]}
          renderRow={(ledgerItem: LedgerItem, index) => {
            let actions: IActionListItem[] = [
              { key: 'download_pdf', content: 'Download PDF' },
              { key: 'download_ubl', content: 'Download UBL' },
            ]

            return (
              <ResourceTableRow key={ledgerItem.id}>
                <ResourceTableRowData verticalAlign='top'>
                  <b>
                    {ledgerItem.number}
                  </b>
                </ResourceTableRowData>
                <ResourceTableRowData>
                  <div>
                    <b>
                      {ledgerItem.contact ? ledgerItem.contact.name : '-'}
                    </b>
                  </div>

                  <div style={{ fontSize: 12 }}>
                    {ledgerItem.contact && ledgerItem.contact.vat_number ? ledgerItem.contact.vat_number : '-'}
                  </div>
                </ResourceTableRowData>
                <ResourceTableRowData style={{ maxWidth: 100 }}>
                  <DateInput
                    name='booked_on'
                    dateFormat={setting.date_format}
                    timeFormat={false}
                    initialValue={moment(ledgerItem.booked_on)}
                    value={moment(ledgerItem.booked_on)}
                    inputProps={{ placeholder: setting.date_format }}
                    onChange={(bookedOn) => this.onBookedOnChange(index, bookedOn)}
                    closeOnSelect
                  />
                </ResourceTableRowData>
                <ResourceTableRowData textAlign='right'>
                  <div>
                    <b>
                      {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, ledgerItem.amount)}
                    </b>
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, ledgerItem.subtotal)} excl. btw`}
                  </div>
                </ResourceTableRowData>
                <ResourceTableRowActions
                  actions={actions}
                  onActionClick={(key) => this.onActionClick(key, ledgerItem)}
                  sticky={true}
                  stickyRight='0px'
                />
              </ResourceTableRow>
            )
          }}
          pagination={{ page: currentPage, pageCount: totalPages }}
          onPageChange={(page) => this.fetchLedgerItems(page)}
          renderEmpty={<CardEmptyInfo
            icon='invoice'
            description={'No invoices or creditnotes found'}
          />}
          stickyHeader={true}
          maxHeight='65vh'
        />}

        <Modal show={showExportInvoicesModal} onClose={this.onExportInvoicesModalClose} >
          <ExportInvoicesModal close={this.onExportInvoicesModalClose} />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentAccountant,
    }
  } = state

  return {
    currentAccountant: currentAccountant,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)