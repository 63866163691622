import * as React from 'react'
import { Body } from '../components/Body/Body'
import Button from '../components/Button/Button'
import { RouteComponentProps } from 'react-router'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import { Trans, useTranslation } from 'react-i18next'

type IProps = RouteComponentProps<any>

const NoMatch = (props: IProps) => {
  const { t } = useTranslation()

  const onBackPress = () => {
    props.history.goBack()
  }

  return (
    <div className='boo-container'>
      <ScrollToTopOnMount />
      <Body className='is-no-match' />
      <div className='boo-wrapper'>
        <div className='boo'>
          <div className='face' />
        </div>
        <div className='shadow' />

        <h1>{t('NoMatch::Whoops!')}</h1>

        <p>
          <Trans t={t}>
            We are unable to find the page you <br /> are looking for
          </Trans>
        </p>

        <p style={{ marginTop: '16px' }}>
          <Button
            type='default'
            text={t('NoMatch::Terug')}
            onClick={onBackPress}
          />
        </p>
      </div>
    </div>
  )
}

export default NoMatch