import * as React from 'react'
import { HashRouter as Router, Route, Switch, Redirect, NavLink, RouteComponentProps } from 'react-router-dom'
import Invoices from './Invoices'
import Expenses from './Expenses'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount'
import ERouteAccountant from '../../accountant/ERouteAccountant'
import PageHeader from '../../components/Page/PageHeader'
import { SessionController } from '../../controllers/accountant'
import { AppState } from '../../store'
import { connect } from 'react-redux'
import PageContent from '../../components/Page/PageContent'
import { AccountantCurrentUser } from '../../types'

interface IStateToProps {
  currentAccountant: AccountantCurrentUser
}

type IProps = IStateToProps


const Dashboard = (props: IProps) => {
  const { currentAccountant: { workspace } } = props
  const logout = async () => {
    try {
      await SessionController.logout()
      window.location.assign('/')
    } catch (ex) {
      console.error(ex)
    }
  }

  const onPageHeaderActionClick = (key: string) => {
    switch (key) {
      case 'sign_out': logout()
        break
    }
  }

  return (
    <>
      <ScrollToTopOnMount />

      <PageContent className='is-accountant'>
        <PageHeader
          title={workspace.business_name ? `Accounting ${workspace.business_name}` : 'Accounting'}
          mainActions={[
            { key: 'sign_out', content: 'Sign out' }
          ]}
          onMainActionClick={onPageHeaderActionClick}
        />

        <div className='content-tabs'>
          <NavLink
            to={ERouteAccountant.PATH_INVOICES}
            className='content-tab'
            activeClassName='is-active'
          >
            Invoices
            <div className='content-tab-indicator' />
          </NavLink>
          <NavLink
            to={ERouteAccountant.PATH_EXPENSES}
            className='content-tab'
            activeClassName='is-active'
          >
            Expenses
            <div className='content-tab-indicator' />
          </NavLink>
        </div>


        <div className='content-tab-content'>
          <Router>
            <Switch>
              <Route path={ERouteAccountant.PATH_INVOICES} component={Invoices} />
              <Route path={ERouteAccountant.PATH_EXPENSES} component={Expenses} />
              <Redirect from='/' to={ERouteAccountant.PATH_INVOICES} />
            </Switch>
          </Router>
        </div>
      </PageContent>
    </>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentAccountant,
    }
  } = state

  return {
    currentAccountant: currentAccountant,
  }
}

export default connect(mapStateToProps)(Dashboard)